import ScrollableListContext from '~/src/components/mui-wrappers/scrollable-list/ScrollableListContext'
import { useContext } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavButtonContainer
  from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/NavButtonContainer'
import NavButton from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/NavButton'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface Props {
  orientation: ScrollableListOrientation,
}

const NextButton = ({ orientation }: Props) => {
  const context = useContext(ScrollableListContext)
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const rightPosition: string = !isMobile ? '-12px' : '5px'

  const handleOnClick = () => {
    context.next()
  }

  return (
    <NavButtonContainer
      orientation={orientation}
      sx={{
        top: orientation === 'horizontal' ? 0 : 'unset',
        bottom: orientation === 'vertical' ? 0 : 'unset',
        right: orientation === 'horizontal' ? rightPosition : 0
      }}
      onClick={handleOnClick}
    >
      <NavButton orientation={orientation}>
        <NavigateNextIcon/>
      </NavButton>
    </NavButtonContainer>
  )
}

export default NextButton
