import { MutableRefObject, useState } from 'react'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'

interface ReturnType {
  display(index: number): void,
  hide(index: number): void,
  prev(): void,
  next(): void,
  currentIndex: number[],
  isScrollable(): boolean,
}

const useScrollableListNavigation = (ref: MutableRefObject<HTMLUListElement | null>, moveNthElement: number, orientation: ScrollableListOrientation): ReturnType => {
  const [ displayIndex, setDisplayIndex ] = useState<number[]>([])

  const prev = (): void => {
    const index: number = displayIndex[0] - moveNthElement

    if (!ref.current?.children?.item(index)) {
      ref.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end'
      })

      return
    }

    ref.current?.children.item(index)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    })
  }

  const display = (index: number): void => {
    setDisplayIndex(state => [ ...state, index ].sort())
  }

  const hide = (index: number): void => {
    setDisplayIndex(state => state.filter(value => value !== index))
  }

  const next = (): void => {
    const index: number = displayIndex[displayIndex.length - 1] + moveNthElement

    if (!ref.current?.children?.item(index)) {
      ref.current?.firstElementChild?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      })

      return
    }

    ref.current?.children?.item(index)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end'
    })
  }

  const isScrollable = (): boolean => {
    const element = ref.current

    if (!element) {
      return false
    }

    if (orientation === 'horizontal') {
      return element.scrollWidth > element.clientWidth
    }

    return element.scrollHeight > element.clientHeight
  }

  return { display, hide, prev, next, currentIndex: displayIndex, isScrollable }
}

export default useScrollableListNavigation
