import { Box, SxProps } from '@mui/material'
import { ReactNode, useRef } from 'react'
import ScrollableListIndicator, {
  ScrollableListIndicatorProps
} from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-indicator'
import useScrollableListDragOption from '~/src/hooks/scrollable-list/useScrollableListDragOption'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'
import ScrollableListContext from '~/src/components/mui-wrappers/scrollable-list/ScrollableListContext'
import useScrollableListNavigation from '~/src/hooks/scrollable-list/useScrollableListNavigation'
import ScrollableListNavButtons from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons'

interface Props {
  className?: string,
  children: ReactNode,
  orientation?: ScrollableListOrientation,
  draggable?: boolean,
  displayNavButtons?: boolean,
  displayIndicator?: boolean,
  moveNthElement?: number,
  IndicatorProps?: Omit<ScrollableListIndicatorProps, 'containerRef' | 'orientation'>,
  sx?: SxProps,
}

const ScrollableList = ({
  className,
  children,
  moveNthElement = 1,
  orientation = 'vertical',
  draggable = false,
  displayNavButtons = false,
  IndicatorProps,
  displayIndicator = true,
  sx = {}
}: Props) => {
  const ref = useRef<HTMLUListElement | null>(null)
  const {
    isScrolling,
    handleOnMouseUp,
    handleOnMouseMove,
    handleOnMouseDown
  } = useScrollableListDragOption(ref, orientation)
  const {
    isScrollable,
    prev,
    next,
    hide,
    display,
    currentIndex
  } = useScrollableListNavigation(ref, moveNthElement, orientation)

  return (
    <ScrollableListContext.Provider value={{
      currentIndex,
      display,
      next,
      prev,
      hide
    }}>
      <Box sx={{ position: 'relative' }}>
        <Box
          className={className}
          component='ul'
          ref={ref}
          onMouseUp={draggable ? handleOnMouseUp : undefined}
          onMouseDown={draggable ? handleOnMouseDown : undefined}
          onMouseMove={draggable ? handleOnMouseMove : undefined}
          onMouseLeave={draggable ? handleOnMouseUp : undefined}
          sx={{
            display: 'flex',
            flexDirection: orientation === 'vertical' ? 'column' : 'row',
            overflow: 'auto',
            listStyleType: 'none',
            userSelect: 'none',
            pb: 1,
            pl: 0,
            gap: 2,
            cursor: isScrolling ? 'grabbing' : 'unset',
            '& > li': {
              pointerEvents: isScrolling ? 'none' : 'auto'
            },
            height: orientation === 'vertical' ? '100%' : 'unset',
            ...sx
          }}
        >
          {children}
        </Box>

        {displayNavButtons && isScrollable() && <ScrollableListNavButtons orientation={orientation}/>}

        {displayIndicator && !displayNavButtons && <ScrollableListIndicator
          containerRef={ref?.current}
          orientation={orientation}
          {...IndicatorProps}
        />
        }
      </Box>
    </ScrollableListContext.Provider>
  )
}

export default ScrollableList
