import PrevButton from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/PrevButton'
import NextButton from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/NextButton'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'

interface Props {
  orientation: ScrollableListOrientation,
}

const ScrollableListNavButtons = ({ orientation }: Props) => {
  return (
    <>
      <PrevButton orientation={orientation}/>
      <NextButton orientation={orientation}/>
    </>
  )
}

export default ScrollableListNavButtons
