import { useContext } from 'react'
import ScrollableListContext from '~/src/components/mui-wrappers/scrollable-list/ScrollableListContext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavButtonContainer
  from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/NavButtonContainer'
import NavButton from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-nav-buttons/NavButton'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface Props {
  orientation: ScrollableListOrientation,
}

const PrevButton = ({ orientation }: Props) => {
  const context = useContext(ScrollableListContext)
  const { isMobile } = useEoValue(LAYOUT_STATE)

  const handleOnClick = () => {
    context.prev()
  }

  if (isMobile) {
    return null
  }

  return (
    <NavButtonContainer
      orientation={orientation}
      sx={{
        top: 0,
        left: orientation === 'horizontal' ? '-12px' : 0
      }}
      onClick={handleOnClick}
    >
      <NavButton orientation={orientation}>
        <NavigateBeforeIcon />
      </NavButton>
    </NavButtonContainer>
  )
}

export default PrevButton
