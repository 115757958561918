import { MouseEvent, MutableRefObject, useState } from 'react'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'
import { Constants } from '~/src/helpers/constants'

interface ScrollState {
  isScrolling: boolean,
  baseClickPosition: number,
}

interface ReturnType {
  isScrolling: boolean,
  handleOnMouseDown(event: MouseEvent): void,
  handleOnMouseMove(event: MouseEvent): void,
  handleOnMouseUp(): void,
}

const useScrollableListDragOption = (ref: MutableRefObject<HTMLUListElement | null>, orientation: ScrollableListOrientation): ReturnType => {
  let timeOut: NodeJS.Timeout
  const [ state, setState ] = useState<ScrollState>({
    isScrolling: false,
    baseClickPosition: 0
  })

  const handleOnMouseDown = (event: MouseEvent) => {
    const slider: HTMLUListElement | null = ref?.current

    if (!slider) {
      return
    }

    event.preventDefault()

    timeOut = setTimeout(() => {
      setState({
        isScrolling: true,
        baseClickPosition: orientation === 'vertical' ? event.pageY - slider.offsetTop : event.pageX - slider.offsetLeft
      })
    }, Constants.SCROLLABLE_LIST_DRAG_DEBOUNCE_TIME)
  }

  const handleOnMouseUp = () => {
    clearTimeout(timeOut)
    const slider: HTMLUListElement | null = ref?.current

    if (!slider || !state.isScrolling) {
      return
    }

    setState({
      ...state,
      isScrolling: false
    })
  }

  const handleOnMouseMove = (event: MouseEvent) => {
    const { baseClickPosition, isScrolling } = state
    const slider: HTMLUListElement | null = ref?.current

    if (!slider|| !isScrolling) {
      return
    }

    event.preventDefault()

    const finalPosition = orientation === 'vertical' ? event.pageY - slider.offsetTop : event.pageX - slider.offsetLeft
    const delta = (finalPosition - baseClickPosition) * 2

    slider.scrollTo({
      left: orientation === 'horizontal' ? slider.scrollLeft - delta : undefined,
      top: orientation === 'vertical' ? slider.scrollTop - delta : undefined,
      behavior: 'smooth'
    })
  }

  return { isScrolling: state.isScrolling, handleOnMouseDown, handleOnMouseMove, handleOnMouseUp }
}

export default useScrollableListDragOption
