import { createContext } from 'react'

export interface ScrollableListContext {
  currentIndex: number[],
  display(index: number): void,
  hide(index: number): void,
  prev(): void,
  next(): void,
}

const ScrollableListContext = createContext<ScrollableListContext>({
  currentIndex: [],
  display: () => { throw new Error('ScrollableListContext.display is not correctly implemented') },
  hide: () => { throw new Error('ScrollableListContext.hide is not correctly implemented') },
  prev: () => { throw new Error('ScrollableListContext.prev is not correctly implemented') },
  next: () => { throw new Error('ScrollableListContext.next is not correctly implemented') }
})

export default ScrollableListContext

