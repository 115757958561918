import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'
import useScrollableListIndex from '~/src/hooks/scrollable-list/useScrollableListIndex'

interface Props {
  children: ReactNode,
  index: number,
  sx?: SxProps,
}

const ScrollableListItem = ({ children, index, sx = {} }: Props) => {
  const ref = useScrollableListIndex(index)

  return (
    <Box
      ref={ref}
      component='li'
      sx={{
        width: 'unset',
        ...sx
      }}>
      {children}
    </Box>
  )
}

export default ScrollableListItem
