import { MutableRefObject, useContext, useEffect, useRef } from 'react'
import ScrollableListContext from '~/src/components/mui-wrappers/scrollable-list/ScrollableListContext'

const useScrollableListIndex = (index: number): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null)
  const context = useContext(ScrollableListContext)

  useEffect(() => {
    if (!ref?.current) {
      return
    }

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          context.display(index)
        } else {
          context.hide(index)
        }
      })
    }, {
      threshold: .25
    })

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }

  }, [ ref?.current ])

  return ref
}

export default useScrollableListIndex
