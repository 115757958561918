import Button, { ButtonProps } from '~/src/components/mui-wrappers/buttons/Button'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'

interface Props extends Omit<ButtonProps, 'color' | 'variant' | 'size'> {
  orientation: ScrollableListOrientation,
}

const NavButton = ({ children, orientation, sx, ...otherProps }: Props) => {
  return (
    <Button
      color='primary'
      variant='contained'
      size='small'
      sx={{
        transform: orientation === 'vertical' ? 'rotate(90deg)' : 'unset',
        p: '2px',
        ...sx
      }}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export default NavButton
