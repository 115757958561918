import { Box, BoxProps } from '@mui/material'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'

interface Props extends BoxProps {
  orientation: ScrollableListOrientation,
}

const NavButtonContainer = ({ sx, children, orientation, ...otherProps }: Props) => {
  return (
    <Box sx={{
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: orientation === 'horizontal' ? '100%' : 'unset',
      width: orientation === 'vertical' ? '100%' : 'unset',
      ...sx
    }}
    {...otherProps}
    >
      {children}
    </Box>
  )
}

export default NavButtonContainer
