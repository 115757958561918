import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { SystemCssProperties } from '@mui/system'
import { ScrollableListOrientation } from '~/src/types/ScrollableListOrientation'

export interface ScrollableListIndicatorProps {
  containerRef: HTMLUListElement | null,
  orientation: ScrollableListOrientation,
  indicatorPosition?: 'inner' | 'center' | 'outer',
  indicatorButton?: boolean,
  sx?: SxProps,
}

const ScrollableListIndicator = ({
  containerRef,
  orientation,
  indicatorPosition = 'center',
  indicatorButton = false,
  sx = {}
}: ScrollableListIndicatorProps) => {
  const [ isDisplay, setIsDisplay ] = useState<boolean>(false)
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const muiTheme = useCustomMuiTheme()

  const _handleOnClick = () => {
    if (!indicatorButton) {
      return
    }

    containerRef?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: isMobile ? 'center' : 'end',
      inline: 'nearest'
    })
  }

  const _getPositionsSxProps = (): Pick<SystemCssProperties, 'top' | 'right' | 'bottom'> => {
    if (orientation === 'horizontal') {
      return {
        right: isMobile ? `calc(var(--eo-safe-area-right) + ${muiTheme.spacing(1)})` : `-${_getIndicatorInContainerPosition()}`,
        bottom: 'unset'
      }
    }

    return {
      top: 'unset',
      right: 'calc(50% - (2.5rem / 2))',
      bottom: isMobile ? `calc(var(--eo-safe-area-bottom) + ${muiTheme.spacing(1)})` : _getIndicatorInContainerPosition()
    }
  }

  const _getIndicatorInContainerPosition = (): string => {
    switch (indicatorPosition) {
      case 'inner':
        return '0'
      case 'center':
        return '1.25rem'
      case 'outer':
        return '2.50rem'
    }
  }

  const listenScroll = (event: HTMLElementEventMap['scroll']) => {
    setIsDisplay((event.target as any).scrollLeft <= 50)
  }

  const clearListener = () => {
    containerRef && containerRef.removeEventListener('scroll', listenScroll)
  }

  useEffect(() => {
    return () => {
      clearListener()
    }
  }, [])

  useEffect(() => {
    if (!containerRef || containerRef.scrollWidth <= containerRef.getBoundingClientRect().width) {
      return setIsDisplay(false)
    }

    clearListener()
    setIsDisplay(true)
    containerRef && containerRef.addEventListener('scroll', listenScroll)
  }, [ containerRef, containerRef?.scrollWidth ])

  if (!isDisplay) {
    return null
  }

  return (
    <Box
      component='span'
      sx={{
        position: 'absolute',
        top: 'calc(40% - 1.5rem)',
        border: 'solid 2px',
        borderColor: (muiTheme.palette as any).background.contrastText,
        background: (muiTheme.palette as any).background.main,
        color: (muiTheme.palette as any).background.contrastText,
        borderRadius: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: orientation === 'vertical' ?  'rotate(90deg)' : 'unset',
        padding: 2.5,
        height: '2.5rem',
        width: '2.5rem',
        boxShadow: muiTheme.shadows[6],
        cursor: indicatorButton ? 'pointer' : 'unset',
        ...sx,
        ..._getPositionsSxProps()
      }}
      onClick={_handleOnClick}
    >
      <Box
        component='span'
        sx={{
          height: '2rem',
          width: '2rem'
        }}
      >
        <ArrowForwardIcon
          color='inherit'
          sx={{
            fontSize: '2rem !important'
          }}
        />
      </Box>
    </Box>
  )
}

export default ScrollableListIndicator
